import AWS from 'aws-sdk'




AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ID
})

const S3Bucket = new AWS.S3({
  params: { Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME},
  region: process.env.REACT_APP_AWS_REGION,
})

export default S3Bucket;